import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Nav, Tab, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap'
import Card from '../../../components/Card'
import LoaderModal from '../../../components/loaderModal'
import CustomToggle from '../../../components/dropdowns'
import ShareOffcanvas from '../../../components/share-offcanvas'
import { Link, useNavigate, Route } from 'react-router-dom'
import { apiHandler } from '../../../api/apihandler';
import { handleImageUpload } from "../../../api/uploads";
import { FEEDS_LIST, FOLLOWER_LIST, FOLLOWING_LIST, PROFILE_COVER_PIC_UPDATE, PROFILE_DETAILS, PROFILE_GALLERY } from "../../../api/constants";
import Moment from 'moment';
import ReactFsLightbox from 'fslightbox-react';
import Swal from 'sweetalert2'
import { InView } from "react-intersection-observer";

// images
import img1 from '../../../assets/images/page-img/profile-bg1.jpg'
import g1 from '../../../assets/images/page-img/g1.jpg'
import g2 from '../../../assets/images/page-img/g2.jpg'
import g3 from '../../../assets/images/page-img/g3.jpg'
import g4 from '../../../assets/images/page-img/g4.jpg'
import g5 from '../../../assets/images/page-img/g5.jpg'
import g6 from '../../../assets/images/page-img/g6.jpg'
import g7 from '../../../assets/images/page-img/g7.jpg'
import g8 from '../../../assets/images/page-img/g8.jpg'
import g9 from '../../../assets/images/page-img/g9.jpg'
import loader from '../../../assets/images/page-img/page-load-loader.gif'
import img51 from '../../../assets/images/page-img/51.jpg'
import img52 from '../../../assets/images/page-img/52.jpg'
import img53 from '../../../assets/images/page-img/53.jpg'
import img54 from '../../../assets/images/page-img/54.jpg'
import img55 from '../../../assets/images/page-img/55.jpg'
import img56 from '../../../assets/images/page-img/56.jpg'
import img57 from '../../../assets/images/page-img/57.jpg'
import img58 from '../../../assets/images/page-img/58.jpg'
import img59 from '../../../assets/images/page-img/59.jpg'
import img60 from '../../../assets/images/page-img/60.jpg'
import img61 from '../../../assets/images/page-img/61.jpg'
import img62 from '../../../assets/images/page-img/62.jpg'
import img64 from '../../../assets/images/page-img/64.jpg'
import img65 from '../../../assets/images/page-img/65.jpg'
import img63 from '../../../assets/images/page-img/63.jpg'
import FeedsList from '../../../components/feeds/feedsList'
import CreatePostModal from '../../../components/createPost/createPostModal'
import StoriesSection from '../../../components/stories/storiesSection'

// Redux Selector / Action
import { useDispatch, useSelector } from 'react-redux';
import { setUserData, setCreatePostType } from '../../../store/setting/actions'

import GalleryImage from '../../../components/gallery/galleryImage'
import GalleryVideo from '../../../components/gallery/galleryVideo'
import GalleryImageCard from '../../../components/gallery/galleryImageCard'
import FeedPostCard from '../../../components/feeds/feedPostCard'
import NoDataFound from '../../../components/noDataFound'
import CreatePostInputBox from '../../../components/createPost/createPostInputBox'

// Fslightbox plugin
const FsLightbox = ReactFsLightbox.default ? ReactFsLightbox.default : ReactFsLightbox;



const UserProfile = () => {
   const [show, setShow] = useState(false);
   const navigate = useNavigate();
   const dispatch = useDispatch()
   let profileData = (useSelector((state) => state.setting.setting.user_data))
   profileData = profileData ? JSON.parse(profileData) : "";
   const [page, setPage] = useState(1);
   const [maxPage, setMaxPage] = useState(1);
   const [followerList, setFollowerList] = useState([]);
   const [followingList, setFollowingList] = useState([]);
   const [galleryImageList, setGalleryImageList] = useState([]);
   const [galleryVideoList, setGalleryVideoList] = useState([]);
   const [feedsList, setFeedsList] = useState([]);
   const [showLoader, setShowLoader] = useState(false);
   const [showMoreDataLoader, setShowMoreDataLoader] = useState(false);

   const [imageController, setImageController] = useState({
      toggler: false,
      slide: 1
   });
   function imageOnSlide(number) {
      setImageController({
         toggler: !imageController.toggler,
         slide: number
      });
   }

   useEffect(() => {
      console.log("token", localStorage.getItem("uzoneAuthToken"))
      dispatch(setCreatePostType("0"))
      if (localStorage.getItem("uzoneAuthToken")) {
         getProfileDetails()
         getFollowerList()
         getFollowingList()
         getGalleryImage()
         getGalleryVideo()
      } else {
         navigate('/auth/sign-in')
      }
   }, []);

   async function getProfileDetails() {
      try {
         const res = await apiHandler(PROFILE_DETAILS, "POST")
         if (res.data?.response?.status === 200) {
            //console.log("profileRes", res)
            //setProfileData(res?.data?.data?.user)
            dispatch(setUserData(JSON.stringify(res?.data?.data?.user)));
            getFeedsList(res?.data?.data?.user?.id, 1)
         } else {
            navigate('/auth/sign-in')
            //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
            //console.log("profileRes",res)
         }
      } catch (error) {
         console.error(error);
      }
      finally {
      }
   }

   const loadMore = (inView) => {
      if (inView) {
         if (page < maxPage) {
            getFeedsList(profileData?.id, page + 1)
            //console.log("scroll working...", page + 1)
            setPage(page + 1)
         }
      }
   };

   function refreshApi() {
      setPage(1)
      getFeedsList(profileData?.id, 1)
   }

   async function getFeedsList(userId, pageVal) {
      setShowMoreDataLoader(true)
      //setShowLoader(true)
      try {
         const res = await apiHandler(FEEDS_LIST, "POST", {
            user_id: userId,
            page: pageVal,
            limit: 10,
         })
         if (res.data?.response?.status === 200) {
            setMaxPage(res?.data?.data?.feeds?.meta?.last_page)
            if (pageVal === 1) {
               setFeedsList(res?.data?.data?.feeds?.data)
            } else {
               setFeedsList(feedsList.concat(res?.data?.data?.feeds?.data))
            }
         } else {
            //navigate('/auth/sign-in')
            //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
            //console.log("profileRes",res)
         }
      } catch (error) {
         console.error(error);
      } finally {
         setShowMoreDataLoader(false)
         //setShowLoader(false)
      }
   }

   async function getFollowerList() {
      try {
         const res = await apiHandler(FOLLOWER_LIST, "POST", {
            page: 1,
            limit: 9
         })
         if (res.data?.response?.status === 200) {
            if (res?.data?.data?.followers?.meta?.total > 0) {
               setFollowerList(res?.data?.data?.followers?.data)
            }
         } else {
            //navigate('/auth/sign-in')
            //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
            //console.log("profileRes",res)
         }
      } catch (error) {
         console.error(error);
      }
      finally {
      }
   }

   async function getFollowingList() {
      try {
         const res = await apiHandler(FOLLOWING_LIST, "POST", {
            page: 1,
            limit: 9
         })
         if (res.data?.response?.status === 200) {
            if (res?.data?.data?.followings?.meta?.total > 0) {
               setFollowingList(res?.data?.data?.followings?.data)
            }
         } else {
            //navigate('/auth/sign-in')
            //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
            //console.log("profileRes",res)
         }
      } catch (error) {
         console.error(error);
      } finally {
      }
   }

   async function getGalleryImage() {
      try {
         const res = await apiHandler(PROFILE_GALLERY, "POST", {
            user_id: profileData?.id,
            media_type: 0,
            page_no: 1,
            limit: 1000,
         })
         if (res.data?.response?.status === 200) {
            if (res?.data?.data?.userPostImages?.meta?.total > 0) {
               setGalleryImageList(res?.data?.data?.userPostImages?.data)
            }
         } else {
            //navigate('/auth/sign-in')
            //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
            //console.log("profileRes",res)
         }
      } catch (error) {
         console.error(error);
      } finally {
      }
   }

   async function getGalleryVideo() {
      try {
         const res = await apiHandler(PROFILE_GALLERY, "POST", {
            user_id: profileData?.id,
            media_type: 1,
            page_no: 1,
            limit: 1000,
         })
         if (res.data?.response?.status === 200) {
            if (res?.data?.data?.userPostImages?.meta?.total > 0) {
               setGalleryVideoList(res?.data?.data?.userPostImages?.data)
            }
         } else {
            //navigate('/auth/sign-in')
            //addToast(res?.response?.data?.data?.message, { appearance: 'error' });
            //console.log("profileRes",res)
         }
      } catch (error) {
         console.error(error);
      } finally {
      }
   }

   const handleCoverImageChange = (event) => {
      setShowLoader(true)
      const file = event.target.files[0];
      if (file) {
         const reader = new FileReader();

         reader.onloadend = async () => {
            //setPreviewImage(reader.result);
            const fileCon = new File([file], `coverImage${Date.now()}.jpg`, { type: 'image/jpeg' });
            const url = await handleImageUpload(file, "coverImage/", "image/jpeg")
            // const url = await handleImageUpload(file, "feedImages/", "image/jpeg")
            updateCoverPicApi(url)
         };
         reader.readAsDataURL(file);
      }
   };

   async function updateCoverPicApi(url) {
      try {
         const res = await apiHandler(PROFILE_COVER_PIC_UPDATE, "POST", {
            cover_photo: url
         })
         if (res.data?.response?.status == 200) {
            //setProfileData(res?.data?.data?.user)
            //addToast("Updated Successfully.");
            //setProfileImage(url)
            dispatch(setUserData(JSON.stringify(res?.data?.data?.user)));
            Swal.fire({
               title: 'Success!',
               text: 'Your cover image uploaded successfully.',
               icon: 'success',
               confirmButtonText: 'Okay'
            })
         } else if (res.data?.response?.status == 400) {
            Swal.fire({
               title: 'Error',
               text: res?.response?.data?.data?.message,
               icon: 'error',
               confirmButtonText: 'Okay'
            })
         } else {
            Swal.fire({
               title: 'Error',
               text: res?.response?.data?.data?.message,
               icon: 'error',
               confirmButtonText: 'Okay'
            })
         }
      } catch (error) {
         console.error(error);
      }
      finally {
         setShowLoader(false)
      }
   }


   return (
      <>
         <LoaderModal showLoader={showLoader} />
         <FsLightbox
            toggler={imageController.toggler}
            sources={[g1, g2, g3, g4, g5, g6, g7, g8, g9, img51, img52, img53, img54
               , img55, img56, img57, img58, img59, img60, img61, img62, img63, img64, img65, img51, img52, img53, img54
               , img55, img56, img57, img58, img51, img52, img53, img54
               , img55, img56, img57, img58, img59, img60]}
            slide={imageController.slide}
         />
         <Container style={{ marginTop: "12px" }}>
            <Row>
               <Col sm={12}>
                  <Card>
                     <Card.Body className=" profile-page p-0">
                        <div className="profile-header">
                           <div className="position-relative">
                              <img loading="lazy" src={profileData?.user_setting?.cover_photo ?
                                 profileData?.user_setting?.cover_photo : img1} alt="profile-bg" className="rounded img-fluid cover"
                                 style={{ height: "100%", width: "100%", objectFit: "cover", maxHeight: "250px" }} />
                              <ul className="header-nav list-inline d-flex flex-wrap justify-end p-0 m-0">
                                 <li>
                                    <div >
                                       <label htmlFor="CoverPhotoUpload">
                                          <i className="ri-pencil-line upload-button text-white" alt="preview image" />
                                       </label>
                                       {/* <Link to="#" className="material-symbols-outlined">
                                          edit
                                       </Link> */}
                                       <input className="file-upload"
                                          id="CoverPhotoUpload" type="file" accept="image/*"
                                          multiple="multiple"
                                          onChange={handleCoverImageChange} />
                                    </div>
                                    {/* <Link to="/dashboard/app/user-profile-edit" className="material-symbols-outlined">
                                       edit
                                    </Link> */}
                                 </li>
                                 <li>
                                    {/* <Link to="/dashboard/app/user-account-setting" className="material-symbols-outlined"> */}
                                    <Link to="/dashboard/app/user-profile-edit" className="material-symbols-outlined">
                                       settings
                                    </Link>
                                 </li>
                              </ul>
                           </div>
                           <div className="user-detail text-center mb-3">
                              <div className="profile-img">
                                 {/* <img loading="lazy" src={img2} alt="profile-img1" className="avatar-130 img-fluid" /> */}
                                 <img style={{ height: 130, width: 130 }} loading="lazy"
                                    src={profileData?.user_setting?.photo ?
                                       profileData?.user_setting?.photo :
                                       "https://uzone360live.s3.amazonaws.com/noimage.png"}
                                    alt="profile-img1" className="avatar-130 img-fluid" />
                              </div>
                              <div className="profile-detail">
                                 <h3>{profileData?.first_name && (profileData?.first_name + " " + profileData?.last_name)}</h3>
                              </div>
                           </div>
                           <div className="profile-info p-3 d-flex align-items-center justify-content-between position-relative">
                              <div className="social-links">
                                 {/* <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                                    <li className="text-center pe-3">
                                       <Link to="#"><img loading="lazy" src={img3} className="img-fluid rounded" alt="facebook"/></Link>
                                    </li>
                                    <li className="text-center pe-3">
                                       <Link to="#"><img loading="lazy" src={img4} className="img-fluid rounded" alt="Twitter"/></Link>
                                    </li>
                                    <li className="text-center pe-3">
                                       <Link to="#"><img loading="lazy" src={img5} className="img-fluid rounded" alt="Instagram"/></Link>
                                    </li>
                                    <li className="text-center pe-3">
                                       <Link to="#"><img loading="lazy" src={img6} className="img-fluid rounded" alt="Google plus"/></Link>
                                    </li>
                                    <li className="text-center pe-3">
                                       <Link to="#"><img loading="lazy" src={img7} className="img-fluid rounded" alt="You tube"/></Link>
                                    </li>
                                    <li className="text-center md-pe-3 pe-0">
                                       <Link to="#"><img loading="lazy" src={img8} className="img-fluid rounded" alt="linkedin"/></Link>
                                    </li>
                                 </ul> */}
                              </div>
                              <div className="social-info">
                                 <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                                    <Link to="#">
                                       <li className="text-center ps-3">
                                          <h6>Posts</h6>
                                          <p className="mb-0">{profileData?.post_count}</p>
                                       </li>
                                    </Link>
                                    <Link to="/dashboards/app/follower-list">
                                       <li className="text-center ps-3">
                                          <h6>Followers</h6>
                                          <p className="mb-0">{profileData?.follower_count}</p>
                                       </li>
                                    </Link>
                                    <Link to="/dashboards/app/following-list">
                                       <li className="text-center ps-3">
                                          <h6>Following</h6>
                                          <p className="mb-0">{profileData?.following_count}</p>
                                       </li>
                                    </Link>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
               <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Card className="p-0">
                     <Card.Body className="p-0">
                        <div className="user-tabing">
                           <Nav as="ul" variant="pills" className="d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
                              <Nav.Item as="li" className=" col-12 col-sm-4 p-0 ">
                                 <Nav.Link href="#pills-timeline-tab" eventKey="first" role="button" className=" text-center p-3">Timeline</Nav.Link>
                              </Nav.Item>
                              <Nav.Item as="li" className="col-12 col-sm-4 p-0">
                                 <Nav.Link href="#pills-about-tab" eventKey="second" role="button" className="text-center p-3">About</Nav.Link>
                              </Nav.Item>
                              {/* <Nav.Item as="li" className=" col-12 col-sm-3 p-0">
                                 <Nav.Link href="#pills-friends-tab" eventKey="third" role="button" className="text-center p-3">Friends</Nav.Link>
                              </Nav.Item> */}
                              <Nav.Item as="li" className="col-12 col-sm-4 p-0">
                                 <Nav.Link href="#pills-photos-tab" eventKey="forth" role="button" className="text-center p-3">Gallery</Nav.Link>
                              </Nav.Item>
                           </Nav>
                        </div>
                     </Card.Body>
                  </Card>
                  <Col sm={12}>
                     <Tab.Content>
                        <Tab.Pane eventKey="first" >
                           <Card.Body className=" p-0">
                              <Row>
                                 <Col lg={4}>
                                    {/* <Card>
                                       <Card.Body>
                                          <Link to="#"><span className="badge badge-pill bg-primary font-weight-normal ms-auto me-1  material-symbols-outlined md-14">grade</span> 27 Items for yoou</Link>
                                       </Card.Body>
                                    </Card> */}
                                    {/* <Card>
                                       <div className="card-header d-flex justify-content-between">
                                          <div className="header-title">
                                             <h4 className="card-title">Life Event</h4>
                                          </div>
                                          <div className="card-header-toolbar d-flex align-items-center">
                                             <p className="m-0"><Link to="#"> Create </Link></p>
                                          </div>
                                       </div>
                                       <Card.Body >
                                          <Row>
                                             <Col sm={12}>
                                                <div className="event-post position-relative">
                                                   <Link to="#"><img loading="lazy" src={img9} alt="gallary1" className="img-fluid rounded" /></Link>
                                                   <div className="job-icon-position">
                                                      <div className="job-icon bg-primary p-2 d-inline-block rounded-circle material-symbols-outlined text-white">
                                                         local_mall
                                                      </div>
                                                   </div>
                                                   <div className="card-body text-center p-2">
                                                      <h5>Started New Job at Apple</h5>
                                                      <p>January 24, 2019</p>
                                                   </div>
                                                </div>
                                             </Col>
                                             <Col sm={12}>
                                                <div className="event-post position-relative">
                                                   <Link to="#"><img loading="lazy" src={img10} alt="gallary1" className="img-fluid rounded" /></Link>
                                                   <div className="job-icon-position">
                                                      <div className="job-icon bg-primary p-2 d-inline-block rounded-circle material-symbols-outlined text-white">
                                                         local_mall
                                                      </div>
                                                   </div>
                                                   <div className="card-body text-center p-2">
                                                      <h5>Freelance Photographer</h5>
                                                      <p className="mb-0">January 24, 2019</p>
                                                   </div>
                                                </div>
                                             </Col>
                                          </Row>
                                       </Card.Body>
                                    </Card> */}
                                    <StoriesSection isMyStory={true}/>
                                    <GalleryImageCard userId={profileData?.id} />
                                    {/* <Card>
                                       <div className="card-header d-flex justify-content-between">
                                          <div className="header-title">
                                             <h4 className="card-title">Photos</h4>
                                          </div>
                                       </div>
                                       <Card.Body>
                                          <ul className="profile-img-gallary p-0 m-0 list-unstyled">
                                             <li><Link onClick={() => imageOnSlide(1)} to="#"><img loading="lazy" src={g1} alt="gallary" className="img-fluid" /></Link></li>
                                             <li><Link onClick={() => imageOnSlide(2)} to="#"><img loading="lazy" src={g2} alt="gallary" className="img-fluid" /></Link></li>
                                             <li><Link onClick={() => imageOnSlide(3)} to="#"><img loading="lazy" src={g3} alt="gallary" className="img-fluid" /></Link></li>
                                             <li><Link onClick={() => imageOnSlide(4)} to="#"><img loading="lazy" src={g4} alt="gallary" className="img-fluid" /></Link></li>
                                             <li><Link onClick={() => imageOnSlide(5)} to="#"><img loading="lazy" src={g5} alt="gallary" className="img-fluid" /></Link></li>
                                             <li><Link onClick={() => imageOnSlide(6)} to="#"><img loading="lazy" src={g6} alt="gallary" className="img-fluid" /></Link></li>
                                             <li><Link onClick={() => imageOnSlide(7)} to="#"><img loading="lazy" src={g7} alt="gallary" className="img-fluid" /></Link></li>
                                             <li><Link onClick={() => imageOnSlide(8)} to="#"><img loading="lazy" src={g8} alt="gallary" className="img-fluid" /></Link></li>
                                             <li><Link onClick={() => imageOnSlide(9)} to="#"><img loading="lazy" src={g9} alt="gallary" className="img-fluid" /></Link></li>
                                          </ul>
                                       </Card.Body>
                                    </Card> */}

                                    {
                                       followerList.length > 0 ?
                                          <Card>
                                             <div className="card-header d-flex justify-content-between">
                                                <div className="header-title">
                                                   <h4 className="card-title">Followers</h4>
                                                </div>
                                                <div className="card-header-toolbar d-flex align-items-center">
                                                   <p className="m-0"><Link to="/dashboards/app/follower-list">View All</Link></p>
                                                </div>
                                             </div>
                                             <Card.Body>
                                                <ul className="profile-img-gallary p-0 m-0 list-unstyled">
                                                   {
                                                      followerList.map((elm, i) => (
                                                         <li key={elm?.id}>
                                                            <Link to={`/dashboard/app/friend-profile/${elm?.follower?.slug}`}>
                                                               <img loading="lazy" src={elm?.follower?.user_setting?.photo ?
                                                                  elm?.follower?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="gallary" className="img-fluid" /></Link>
                                                            <h6 className="mt-2 text-center">{elm?.follower?.first_name && (elm?.follower?.first_name + " " + elm?.follower?.last_name)}</h6>
                                                         </li>
                                                      ))
                                                   }
                                                </ul>
                                             </Card.Body>
                                          </Card> : null
                                    }

                                    {
                                       followingList.length > 0 ?
                                          <Card>
                                             <div className="card-header d-flex justify-content-between">
                                                <div className="header-title">
                                                   <h4 className="card-title">Following</h4>
                                                </div>
                                                <div className="card-header-toolbar d-flex align-items-center">
                                                   <p className="m-0"><Link to="/dashboards/app/following-list">View All</Link></p>
                                                </div>
                                             </div>
                                             <Card.Body>
                                                <ul className="profile-img-gallary p-0 m-0 list-unstyled">
                                                   {
                                                      followingList.map((elm, i) => (
                                                         <li key={elm?.id}>
                                                            <Link to={`/dashboard/app/friend-profile/${elm?.following?.slug}`}>
                                                               <img loading="lazy" src={elm?.following?.user_setting?.photo ?
                                                                  elm?.following?.user_setting?.photo : "https://uzone360live.s3.amazonaws.com/noimage.png"} alt="gallary" className="img-fluid" /></Link>
                                                            <h6 className="mt-2 text-center">{elm?.following?.first_name && (elm?.following?.first_name + " " + elm?.following?.last_name)}</h6>
                                                         </li>
                                                      ))
                                                   }
                                                </ul>
                                             </Card.Body>
                                          </Card> : null
                                    }

                                 </Col>
                                 <Col lg={8}>
                                    {/* <CreatePostModal
                                       postType={"0"}
                                       refreshApi={refreshApi}
                                       eventId={""} /> */}
                                    <CreatePostInputBox
                                       postType={"0"}
                                       refreshApi={refreshApi}
                                       eventId={""} />
                                    {
                                       feedsList?.length > 0 ? feedsList?.map((elm, i) => (
                                          <FeedPostCard elm={elm} getFeedsList={refreshApi} key={elm?.id} feedDetails={false} />
                                       )) : <NoDataFound />
                                    }
                                    {
                                       showMoreDataLoader ?
                                          <div className="col-sm-12 text-center">
                                             <img src={loader} alt="loader" style={{ height: "100px" }} />
                                          </div> : null
                                    }
                                    <InView as="div" onChange={(inView, entry) => loadMore(inView)} />
                                    {/* {
                                       profileData ?
                                          <FeedsList userId={profileData?.id} /> : null
                                    } */}
                                 </Col>
                              </Row>
                           </Card.Body>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                           <Tab.Container id="left-tabs-example" defaultActiveKey="about1">
                              <Row>
                                 <Col md={12} className=" ps-4">
                                    <Card>
                                       <Card.Body>
                                          <Tab.Content>
                                             <Tab.Pane eventKey="about1">
                                                <h4>Personal Info</h4>
                                                <hr />
                                                {
                                                   profileData?.user_setting?.bio != "" && profileData?.user_setting?.bio != null ?
                                                      <Row className="mb-2">
                                                         <div className="col-3">
                                                            <h6>About Me:</h6>
                                                         </div>
                                                         <div className="col-9">
                                                            <p className="mb-0">{profileData?.user_setting?.bio}</p>
                                                         </div>
                                                      </Row> : null
                                                }

                                                <Row className="mb-2">
                                                   <div className="col-3">
                                                      <h6>Email:</h6>
                                                   </div>
                                                   <div className="col-9">
                                                      <p className="mb-0">{profileData?.email}</p>
                                                   </div>
                                                </Row>
                                                <Row className="mb-2">
                                                   <div className="col-3">
                                                      <h6>Mobile:</h6>
                                                   </div>
                                                   <div className="col-9">
                                                      <p className="mb-0">{profileData?.user_setting?.mobile_no}</p>
                                                   </div>
                                                </Row>
                                                <Row className="mb-2">
                                                   <div className="col-3">
                                                      <h6>Birth Date:</h6>
                                                   </div>
                                                   <div className="col-9">
                                                      <p className="mb-0">{Moment(profileData?.user_setting?.dob).format('DD MMMM')}</p>
                                                   </div>
                                                </Row>
                                                <Row className="mb-2">
                                                   <div className="col-3">
                                                      <h6>Birth Year:</h6>
                                                   </div>
                                                   <div className="col-9">
                                                      <p className="mb-0">{Moment(profileData?.user_setting?.dob).format('YYYY')}</p>
                                                   </div>
                                                </Row>
                                                <Row className="mb-2">
                                                   <div className="col-3">
                                                      <h6>Gender:</h6>
                                                   </div>
                                                   <div className="col-9">
                                                      <p className="mb-0">{profileData?.user_setting?.gender == 0 ? "Male" :
                                                         profileData?.user_setting?.gender == 1 ? "Female" : "Other"}</p>
                                                   </div>
                                                </Row>
                                                {
                                                   profileData?.user_setting?.website != "" && profileData?.user_setting?.website != null ?
                                                      <>
                                                         <h4 className="mt-2">Websites and Social Links</h4>
                                                         <hr />
                                                         <Row className="mb-2">
                                                            <div className="col-3">
                                                               <h6>Website:</h6>
                                                            </div>
                                                            <div className="col-9">
                                                               <p className="mb-0">{profileData?.user_setting?.website}</p>
                                                            </div>
                                                         </Row>
                                                      </> : null
                                                }
                                             </Tab.Pane>
                                          </Tab.Content>
                                       </Card.Body>
                                    </Card>
                                 </Col>
                              </Row>
                           </Tab.Container>
                        </Tab.Pane>
                        <Tab.Pane eventKey="forth" >
                           <Tab.Container id="left-tabs-example" defaultActiveKey="p1">
                              <Card>
                                 <Card.Body>
                                    <h2>Gallery</h2>
                                    <div className="friend-list-tab mt-2">
                                       <Nav variant="pills" className=" d-flex align-items-center justify-content-left friend-list-items p-0 mb-2">
                                          <li>
                                             <Nav.Link eventKey="p1" href="#pill-photosofyou">Photos</Nav.Link>
                                          </li>
                                          <li>
                                             <Nav.Link eventKey="p2" href="#pill-your-photos" >Videos</Nav.Link>
                                          </li>
                                       </Nav>
                                       <Tab.Content>
                                          <Tab.Pane eventKey="p1">
                                             <GalleryImage galleryImageList={galleryImageList} />
                                             {/* <Card.Body className="p-0">
                                                <div className="d-grid gap-2 d-grid-template-1fr-13">
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(10)} to="#">
                                                            <img loading="lazy" src={img51} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(11)} to="#">
                                                            <img loading="lazy" src={img52} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(12)} to="#">
                                                            <img loading="lazy" src={img53} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(13)} to="#">
                                                            <img loading="lazy" src={img54} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(14)} to="#">
                                                            <img loading="lazy" src={img55} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(15)} to="#">
                                                            <img loading="lazy" src={img56} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(16)} to="#">
                                                            <img loading="lazy" src={img57} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(17)} to="#">
                                                            <img loading="lazy" src={img58} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(18)} to="#">
                                                            <img loading="lazy" src={img59} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(19)} to="#">
                                                            <img loading="lazy" src={img60} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(20)} to="#">
                                                            <img loading="lazy" src={img61} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(21)} to="#">
                                                            <img loading="lazy" src={img62} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(22)} to="#">
                                                            <img loading="lazy" src={img63} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(23)} to="#">
                                                            <img loading="lazy" src={img64} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(24)} to="#">
                                                            <img loading="lazy" src={img65} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(25)} to="#">
                                                            <img loading="lazy" src={img51} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(26)} to="#">
                                                            <img loading="lazy" src={img52} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(27)} to="#">
                                                            <img loading="lazy" src={img53} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(28)} to="#">
                                                            <img loading="lazy" src={img54} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(29)} to="#">
                                                            <img loading="lazy" src={img55} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(30)} to="#">
                                                            <img loading="lazy" src={img56} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(31)} to="#">
                                                            <img loading="lazy" src={img57} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(32)} to="#">
                                                            <img loading="lazy" src={img58} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                </div>
                                             </Card.Body> */}
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="p2">
                                             <GalleryVideo galleryVideoList={galleryVideoList} />
                                             {/* <div className="card-body p-0">
                                                <div className="d-grid gap-2 d-grid-template-1fr-13 ">
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(33)} to="#">
                                                            <img loading="lazy" src={img51} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(34)} to="#">
                                                            <img loading="lazy" src={img52} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(35)} to="#">
                                                            <img loading="lazy" src={img53} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(36)} to="#">
                                                            <img loading="lazy" src={img54} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(37)} to="#">
                                                            <img loading="lazy" src={img55} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(38)} to="#">
                                                            <img loading="lazy" src={img56} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(39)} to="#">
                                                            <img loading="lazy" src={img57} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(40)} to="#">
                                                            <img loading="lazy" src={img58} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(41)} to="#">
                                                            <img loading="lazy" src={img59} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="user-images position-relative overflow-hidden">
                                                         <Link onClick={() => imageOnSlide(42)} to="#">
                                                            <img loading="lazy" src={img60} className="img-fluid rounded" alt="Responsive" />
                                                         </Link>
                                                         <div className="image-hover-data">
                                                            <div className="product-elements-icon">
                                                               <ul className="d-flex align-items-center m-0 p-0 list-inline">
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 60 <i className="material-symbols-outlined md-14 ms-1">thumb_up</i> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 30 <span className="material-symbols-outlined  md-14 ms-1">chat_bubble_outline</span> </Link></li>
                                                                  <li><Link to="#" className="pe-3 text-white d-flex align-items-center"> 10 <span className="material-symbols-outlined md-14 ms-1">
                                                                     forward
                                                                  </span> </Link></li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <OverlayTrigger placement="top" overlay={<Tooltip>Edit or Remove</Tooltip>}>
                                                            <Link to="#" className="image-edit-btn material-symbols-outlined md-16">drive_file_rename_outline</Link>
                                                         </OverlayTrigger>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div> */}
                                          </Tab.Pane>
                                       </Tab.Content>
                                    </div>
                                 </Card.Body>
                              </Card>
                           </Tab.Container>
                        </Tab.Pane>
                     </Tab.Content>
                  </Col>
               </Tab.Container>
            </Row>
         </Container>
      </>
   )

}

export default UserProfile;